.resend-invite-modal {
    .ant-modal-body {
        padding:15px 20px;
    }
    .ant-modal-footer  {
        .btn {
            background-color: #0465C6;
            width: 230px;
        }
    }
}

.Upload_image {
    .ant {
        &-upload {
           &-drag {
            padding:40px 0px;
            &-container {
                .uploadIcon {
                    margin-bottom: 35px;
                    .ant-image {
                        &-img {
                            width: 77px;
                            height: 60px;
                        }
                    }
                }
                
                .ant-upload-hint {
                    font-size: 18px !important;
                    color:#c2c2c2 !important;
                }
            }
            &-icon {
                display: none;
            }
           }
           &.ant-upload-drag {
               p {
                   &.ant-upload-text {
                    color: #000;
                    font-size: 20px;
                    font-weight: 100;
                    span {
                        color:#028161;
                        font-weight: 700;
                    }
                   }
               }
           }
           &-list-item-name {
            display: none;
           }
           &-text-icon {
            display: none;
           }
           &-list-item-card-actions {
            position: absolute;
            z-index: 3;
            width: 9px;
            right: 10px;
            top:5px;
            min-width: 35px!important;
           }
           &-list-item-progress {
            bottom: -36px;
            background-color: #f6f6f6;
           }
           &-list-item {
            height: 100%;
            background-color: #f6f6f6;
                &:hover {
                    .ant-upload-list-item-card-actions-btn {
                        opacity: 1;
                    }
                }
                .ant-upload-list-item-card-actions-btn {
                    opacity: 1;
                    position: relative;
                    min-width: 26px;
                    min-height: 26px;
                    border-radius: 50%;
                    .anticon {
                        background:#fb6c6c;
                        text-align: center;
                        min-width: 26px!important;
                        min-height: 26px;
                        width: 26px;
                        height: 26px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
            &-list-item-info {
                margin-bottom: 15px;
                &::before {
                    font-weight: bold;
                    color: black;
                    content: "Uploading image";
                    width: 100%;
                    display: block;
                    padding: 5px 24px;
                    position: relative;
                    left: -1px;
                    padding-bottom: 10px;
                }
            }
            &-list-item-card-actions {
                button {
                    .anticon {
                        svg {
                            display: none;
                           
                        }
                        &::before {
                            font-weight: bold;
                            color: #fff;
                            content: "x";
                            width: 100%;
                            display: block;
                            padding: 5px 24px;
                            position: relative;
                            padding-bottom: 10px;
                            font-size: 24px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
        &-modal-title   {
            text-align: center;
        }
        &-progress-text {
            display: block;
            position: relative;
            top: -30px;
            left: -8px;
        }

    }
}
.Upload_complete {
    .ant-modal-title {
        text-align: center;
    }
    .ant-modal-body {
        padding:24px 24px 10px 24px;
    }
    .ant-image {
        border: 1px dashed #c2c2c2;
        width: 100%;
        height: 250px;
        margin-bottom: 10px;
        img {
            padding: 10px;
            max-height: 250px;
        }
    }
    .upload-text-block {
        background-color: #f6f6f6;
        padding: 25px 16px;
        display: flex;
        justify-content: space-between;
        p {
            font-size: 14px;
            font-weight: 700;
        }
        .ant-image {
            margin-right: -29px;
            border: none;
            height:0px;
            margin-bottom: 0px;
            width: 12%;
            img {
                width: 40%;
                padding:0px
            }
        }
    }
}

.ant-pagination-item-link {
    .anticon {
        svg {
            display: flex;
        }
    }
}

.create-admin {
    .ant-card-body {
        padding: 24px 24px 60px 24px;
        .btns-group {
            margin-top:30px;
        }
    }
}

.link_disable_manage {
    min-width: 100px;
    width: 100px;
    pointer-events: none !important;
    cursor: not-allowed !important;
    button.disable{
        cursor: not-allowed;
        pointer-events: none;
        background-color: #3a3a3c;
        opacity: 0.5;
    }
    
}

.errorpage-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,75%);
    text-align: center;
    h1 {
        font-size: 32px;
        padding: 10px;
        color: #cbcbcb;
    }
}

.ant-notification-notice-message {
    text-transform: capitalize !important;
}
.admin-table-wrap {
    .ant-table-content {
        height: 700px;
    overflow: auto;
    }
    .ant-table-thead {
        tr {
            th {
                position: sticky !important;
                top: 0;
                z-index: 1;
            }
        }
    }
}