.ant-modal-content {
    border-radius: 5px;
    box-shadow: @box-shadow;
    overflow: hidden;
    padding: 15px 0;
    .ant-modal-close-x {
        display: none;
    }
    .ant-modal-title {
        font-size: @font-regular;
        font-weight: @bold;
    }
    .ant-form-item-required {
        font-size: @font-regular - 1;
        &::before {
            display: none !important;
        }
    }
}

.ant-modal-header,
.ant-modal-footer {
    border: 0;
}

.ant-modal-header {
    padding-bottom: 0;
}

.modal-sm {
    max-width: 350px;
    .ant-modal-header {
        padding: 0;
    }
    .ant-modal-body {
        padding: 15px 24px;
    }
    .ant-modal-title {
        text-align: center;
        h5 {
            font-size: @font-md;
            font-weight: @semibold;
            margin-top: 15px;
            margin-bottom: 0;
        }
    }
    .ant-modal-content {
        padding: 30px 25px;
    }
}

.ant-modal-content {
    border-radius: 4px;
}

.loginas-modal {
    max-width: 450px;
    .ant-modal-content {
        padding: 20px;
    }
}