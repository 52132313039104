.info-wrapper {
    border-right: 1px solid #F1F1F3;
    padding: 15px;
    position: relative;
    .btn {
        position: absolute;
        top: 0;
        right: 25px;
    }
}

.info-block {
    .head {
        font-size: @font-regular;
        font-weight: @bold;
        margin-bottom: 15px;
    }
    &-inner {
        background-color: #F5F6FA;
        border-radius: @radius;
        padding: 15px 10px;
        margin-bottom: 25px;
        box-shadow: @box-shadow;
        @media (max-width:1400px){
            padding: 5px 3px;
        }
    }
    table {
        td {
            padding-bottom: 20px;
            vertical-align: top;
            @media screen and (max-width: 1280px) {
                font-size: 12px;
            }
        }
        tr{
            &:last-child {
                td {
                   padding-bottom: 0; 
                }
            }
            td{
                &:last-child {
                    width: 60%;
                }
            }
        }
    }
}

.link_disable {
    cursor: not-allowed;
    margin-bottom: 15px;
    a.disable{
        cursor: not-allowed;
        pointer-events: none;
        background-color: #3a3a3c;
        opacity: 0.5;
    }
}

.link_enable {
    margin-bottom: 15px;
    cursor: pointer;
}

.account-info {
    &.info-wrapper {
        .header-block {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .btn {
                position: relative;
                top:0;
                right:0;
            }
        }
    }
    .profile-table {
        tbody {
            tr {
                display: flex;
                td {
                    .colon-right {
                        margin-left: 20px;
                    }
                    strong {
                        margin-left: 25px;
                    }
                }
            }
        }
    }
}
.accountinfo-table {
    .ant-row {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

@media screen and (max-width:1300px){
    .accountinfo-table {
        .ant-row {
            flex-direction: column;
            .ant-col {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width:1500px) and (min-width:1301px){
    .accountinfo-table {
        .ant-row {
            .ant-col {
                .ant-table-tbody {
                    tr {
                        td {
                            text-align: center;
                        }

                    }
                }
            }
        }
    }
}

@media screen and (max-width:1550px) {
    .accountinfo-table {
        .ant-table-thead {
            tr {
                th {
                    padding: 10px 5px;
                }
            }
        }
    }
}

.accountInfo {
    .header-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            position: relative;
            top: 0;
            right: 0;
        }
    }
    
}


.invoice-table {
    .ant-table-thead {
        th:nth-child(1) {
            width:5%;
        }
        th:nth-child(2) {
            width:15%;
        }
        th:nth-child(3) {
            width:15%;
        }
        th:nth-child(4) {
            width:12%;
        }
        th:nth-child(5) {
            width:16%;
        }
        th:nth-child(6) {
            width:12%;
        }
        th:nth-child(7) {
            width:10%;
        }
        th:nth-child(8) {
            width:15%;
        }
    }
    .ant-table-tbody {
        tr {
            td {
                padding: 10px 5px;
                &:nth-child(4) {
                    text-transform: capitalize;
                }
            }
        }
    }
}

.current-period-toast {
    font-size: 13px;
    .ant-image {
        width: 16px;
        margin-right: 5px;
    }
    &.edit-toast {
        position: absolute;
        top: -31px;
        left: 75px;
        p {
            white-space: nowrap;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: default;    
            max-width: 314px;
            @media screen and (max-width: 1600px) {
                max-width: 220px;
            }
            @media screen and (max-width: 1536px) {
                max-width: 207px;
            }
            @media screen and (max-width: 1366px) {
                max-width: 152px;
            }
            @media screen and (max-width: 1280px) {
                max-width: 140px;
            }
        }
    }
    @media screen and (max-width: 1360px) {
        font-size: 11px;
        .ant-image {
            width: 13px;
        }
        &.toast-message {
            padding: 2px 6px;
        }
    }
    @media screen and (max-width: 1280px) {
        .ant-image {
            width: 10px;
            margin-right: 2px;
        }
        font-size: 10px;
    }
}

.period-toast {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1366px) {
        flex-direction: column;
        align-items: flex-start;
        .toast-message {
            margin-left: 0 !important;
        }
    }
}

.studio-table-wrap {
    height: 720px;
    position: relative;
    overflow: auto;
    @media (max-width: 1680px) {
        height: 525px;
    }
    @media (max-width: 1366px) {
        height: 420px;
    }
    @media (max-width: 1280px) {
        height: 365px;
    }
}

.loader-xl {
    font-size: 25px !important;
    position: absolute;
    top: 75px;
    width: 100%;
    background-color: white;
    height: 67px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.discountNumber {
    color:#c53c46;
}

.studio-table-wrap {
    thead {
        @media screen and (max-width:1280px) {
            th {
                font-size: 12px;
            }
        }
    }
    .ant-table-sticky-holder {
        overflow: visible !important;
    }
    tbody {  
        td {
            &:first-child{
                width: 45px;
            }
            &:nth-child(2) {
                word-break: break-all;
                white-space: normal;
            }
            &:nth-child(3) {
                word-break: break-all;
                white-space: normal;
            }
            &:nth-child(4) {
                word-break: break-all;
                white-space: normal;
            }
            &:nth-child(5) {
                width: 150px;
            }
            &:nth-child(6) {
                width: 150px;
            }
            &:nth-child(7) {
                width: 150px;
            }
            &:nth-child(8) {
                width: 150px;
            }
            &:nth-child(9) {
                width: 150px;
            }
        }      
        @media screen and (max-width:1680px) {
            td {
                &:nth-child(2) {
                    width: 170px;
                }
                &:nth-child(3) {
                    width: 175px;
                }
                &:nth-child(4) {
                    width: 175px;
                }
                &:nth-child(5) {
                    width: 100px;
                }
                &:nth-child(6) {
                    width: 100px;
                }
                &:nth-child(7) {
                    width: 70px;
                }
                &:nth-child(8) {
                    width: 70px;
                }
                &:nth-child(9) {
                    width: 100px;
                }
            }
            
        }
        // @media screen and (max-width:1536px) {
        //     td {
        //         &:nth-child(3) {
        //             width: 220px;
        //         }
        //     }
        // }
        @media screen and (max-width:1366px) {
            td {
                &:nth-child(2) {
                    width: 120px;
                }
                &:nth-child(3) {
                    width: 130px;
                }
                &:nth-child(4) {
                    width: 130px;
                }
                &:nth-child(5) {
                    width: 90px;
                }
                &:nth-child(6) {
                    width: 100px;
                }
                &:nth-child(7) {
                    width: 80px;
                }
                &:nth-child(8) {
                    width: 80px;
                }
                &:nth-child(9) {
                    width: 90px;
                }
                .btn {
                    padding: 5px;
                    min-width: 70px;
                    height: 35px;
                    font-size: 14px;
                }
            }
        }
        @media screen and (max-width:1280px) {
            td {
                font-size: 13px;
                &:nth-child(5) {
                    width: 95px;
                }
                &:nth-child(6) {
                    width: 100px;
                }
                &:nth-child(7) {
                    width: 80px;
                }
                &:nth-child(8) {
                    width: 80px;
                }
                &:nth-child(9) {
                    width: 100px;
                }
            }
        }
        @media screen and (max-width:1200px) {
            td {
                font-size: 13px;
                &:nth-child(2) {
                    width: 150px;
                }
                &:nth-child(3) {
                    width: 150px;
                }
                &:nth-child(4) {
                    width: 150px;
                }
            }
        }
    }
    .ant-table-sticky-scroll {
        display: none;
    }
    .ant-table-header {
        overflow: visible;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.admin-table {
    thead {
        @media screen and (max-width:1280px) {
            th {
                font-size: 12px;
            }
        }
    }
    .ant-table-sticky-holder {
        overflow: visible !important;
    }
    tbody {  
        td {
            &:first-child{
                width: 45px;
            }
            &:nth-child(2) {
                word-break: break-all;
                white-space: normal;
            }
            &:nth-child(3) {
                word-break: break-all;
                white-space: normal;
            }
            &:nth-child(4) {
                word-break: break-all;
                white-space: normal;
            }
            &:nth-child(5) {
                width: 150px;
            }
            &:nth-child(6) {
                width: 150px;
            }
            &:nth-child(7) {
                width: 200px;
            }
        }      
        @media screen and (max-width:1536px) {
            td {
                &:nth-child(5) {
                    width: 120px;
                }
                &:nth-child(6) {
                    width: 120px;
                }
                &:nth-child(7) {
                    width: 136px;
                }
            }
            
        }
        @media screen and (max-width:1366px) {
            td {
                .btn {
                    padding: 5px;
                    min-width: 70px;
                    height: 35px;
                    font-size: 14px;
                }
            }
        }
        @media screen and (max-width:1280px) {
            td {
                font-size: 13px;
                &:nth-child(2) {
                    width: 190px;
                }
                &:nth-child(3) {
                    width: 190px;
                }
                &:nth-child(4) {
                    width: 190px;
                }
                &:nth-child(5) {
                    width: 100px;
                }
                &:nth-child(6) {
                    width: 80px;
                }
                &:nth-child(7) {
                    width: 100px;
                }
            }
        }
        @media screen and (max-width:1200px) {
            td {
                font-size: 13px;
                &:nth-child(2) {
                    width: 150px;
                }
                &:nth-child(3) {
                    width: 150px;
                }
                &:nth-child(4) {
                    width: 150px;
                }
            }
        }
        @media screen and (max-width:1120px) {
            td {
                font-size: 13px;
                &:nth-child(2) {
                    width: 130px;
                }
                &:nth-child(3) {
                    width: 130px;
                }
                &:nth-child(4) {
                    width: 130px;
                }
            }
        }
    }
    .ant-table-sticky-scroll {
        display: none;
    }
    .ant-table-header {
        overflow: visible;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 0;
    animation: slide 0.3s;
    box-shadow: 0 20px 30px rgba(#000, 0.2);
    background: rgba(#f3f3f3, 0.5);
}

.ant-table-summary {
    display: none;
}