.ant-input, .ant-input-password, .ant-form-item-control-input-content {
    border-radius: 12px;
    height: 42px;
    color: @p-text-color;
}
.search__wrapper{
    .ant-input{
        min-width: 300px;
    }
}
.ant-form{
    .ant-cascader-picker{
        border-radius: 12px;
    }
    .ant-input-password {
        .ant-input{
            height: auto;
            border-radius: 0;
        }
    }
    .ant-select-selector{
        height: 42px !important;
        // border-radius: 12px !important;
        display: flex;
        align-items: center;
        .ant-select-selection-search{
            top: unset;
        }
    }
    .ant-picker{
        height: 42px !important;
        border-radius: 12px;
    }
    .ant-picker-panel-container{
        border-radius: 12px;
        overflow: hidden;
    }
}
.ant-select-dropdown{
    border-radius: 0.625rem;
}
.ant-input-group-addon{
    border-radius: 0.625rem;
}

.table-search {
    height: 42px;
    background-color: #f5f6fa;
    
    padding: 4px 11px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    input {
        outline: 0;
        border: 0;
        background-color: transparent;
        padding-right: 5px;
    }
    .ant-input-affix-wrapper {
        height: 42px;
        background-color: #F5F6FA;
        border-radius: 4px;
        border: 0;
        .ant-input {
            height: auto;
            background-color: transparent;
        }
    }
}

.ant-form-item-label {
    max-width: 100%;
    label {
        color: @p-text-color !important;
    }
}

.ant-picker {
    border-radius: 4px !important;
    width: 100%;
}

.label_nowrap {
    label {
        white-space: nowrap;
    }
}

.ant-form-item {
    margin-bottom: 0;
}