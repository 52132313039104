@font-face {
    font-family: 'DIN 2014';
    src: local('DIN 2014'),
         url('./extrabold/DIN2014-ExtraBold.woff') format('woff'),
         url('./extrabold/DIN2014-ExtraBold.woff2') format('woff2'),
         url('./extrabold/DIN2014-ExtraBold.ttf') format('truetype');
    font-weight: 900;
}

@font-face {
    font-family: 'DIN 2014';
    src: local('DIN 2014'),
         url('./bold/DIN2014-Bold.woff') format('woff'),
         url('./bold/DIN2014-Bold.woff2') format('woff2'),
         url('./bold/DIN2014-Bold.ttf') format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'DIN 2014';
    src: local('DIN 2014'),
         url('./semibold/DIN2014-DemiBold.woff') format('woff'),
         url('./semibold/DIN2014-DemiBold.woff2') format('woff2'),
         url('./semibold/DIN2014-DemiBold.ttf') format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: 'DIN 2014';
    src: local('DIN 2014'),
         url('./regular/DIN2014-Regular.woff') format('woff'),
         url('./regular/DIN2014-Regular.woff2') format('woff2'),
         url('./regular/DIN2014-Regular.ttf') format('truetype');
    font-weight: 400;
}