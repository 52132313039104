.page__header {
  display: flex;
  box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
  background-color: @white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  z-index: 10;
  background-image: @theme-gradient;
  .responsive__search {
    position: fixed;
    left: 0;
    right: 0;
    height: 70px;
    background-color: @white;
    z-index: 9;
    justify-content: center;
    padding: 14px;
    transform: translateY(-100%);
    transition: all 0.4s ease;
    align-items: center;
    display: none;
    .search__wrapper {
      display: block !important;
      flex-grow: 1;
      margin-right: 15px;
      input {
        min-width: 230px !important;
      }
    }
    .btn__close__search {
      padding: 0;
      border: none;
    }
    // @include lg{
    //     display: flex;
    // }
    &.show {
      transform: translateY(0);
    }
  }

  .header__main {
    width: calc(100% - 250px);
    // @include flexAlignCenter;
    display: flex;
    align-items: center;
    flex-grow: 1;
    .search__wrapper {
      position: relative;
      .ant-btn {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        color: #72849a;
        border: none;
        padding: 0;
        font-size: 17px;
        background-color: transparent;
      }
      .ant-input {
        padding-left: 30px;
      }
      // @include lg{
      //     display: none;
      // }
    }
    .navigation__right {
      margin-bottom: 0;
      margin-left: auto;
      height: 100%;
      // @include flexAlignCenter;
      display: flex;
      align-items: center;
      list-style: none;
      li {
        height: 100%;
        margin-right: 1rem;
        // @include flexAlignCenter;
        // &.lg__hidden{
        //     @include lg{
        //         display: none;
        //     }
        // }
        &.display__lg {
          display: none;
          // @include lg{
          //     display: block;
          // }
        }
        .ant-btn {
          font-size: 22px;
          // @include flexAlignCenter;
          height: 100%;
          &.ant-dropdown-trigger {
            border: none;
            background-color: transparent !important;
            height: 100%;
            &.btn__notification {
              border: none !important;
              position: relative;
              .ant-badge {
                position: absolute;
                top: 15px;
                right: 5px;
              }
            }
            &.btn__profile {
              .profile__image {
                height: 40px;
                width: 40px;
                border-radius: 50%;
                overflow: hidden;
                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
        // @include lg{
        //     margin-right: 5px;
        //     &:last-child{
        //         margin-right: 1rem;
        //     }
        // }
      }
    }
    .username_role {
      text-align: center;
      line-height: 15px;
      .user__name {
        color: @white;
        font-size: @font-regular - 1;
        font-weight: @regular;
      }
      span.work__department {
        font-size: @font-14 - 1;
        color: @white;
        font-weight: @medium;
        display: flex;
      justify-content: flex-end;
      }
    }
    
  }
}
.theme__switch__row {
  display: flex;
  align-items: center;
  .anticon {
    font-size: 16px !important;
    margin-right: 1rem !important;
  }
  .ant-switch {
    flex-grow: 0;
    margin-left: auto;
  }
}
.ant-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 1rem;
  background-color: #fff;
  .ant-avatar-string {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    transform: none !important;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .ant-image {
    width: 100%;
    background-color: #fff;
  }
}
.ant-dropdown {
  .ant-dropdown-arrow {
    display: none !important;
  }
  .ant-dropdown-menu {
    border-radius: 0.625rem;
    // overflow: hidden;
    &.notification__dropdown {
      width: 19.5rem;
      .ant-dropdown-menu-item {
        padding: 10px;
        &:first-child {
          &:hover {
            cursor: pointer;
          }
        }
        .notification__header {
          // @include flexAlignCenter;
          justify-content: space-between;
        }
        .notification__list {
          display: flex;
          align-items: center;
          .comment__section {
            white-space: pre-wrap;
            margin-right: 1rem;
            .commentor__name {
              font-weight: @bold;
            }
          }
        }
      }
    }
    &.profile__dropdown {
      width: 14.5rem;
      .ant-dropdown-menu-item {
        padding: 10px 16px;
        &:first-child {
          border-bottom: 1px solid;
          margin-bottom: 5px;
        }
      }
      .link__iocns {
        display: inline-block;
        margin-right: 1rem;
      }
      // .profile__section{
      //     @include flexAlignCenter;
      // }
      .profile__section {
        h4 {
          font-size: 17px;
          margin-bottom: 0;
        }
        span {
          font-size: 14px;
        }
      }
      &::after {
        content: '';
        position: absolute;
        top: -8px;
        right: 23px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid white;
      }
    }
  }
}

.avatar_container {
  position: relative;
  .ant-avatar {
    // background: @primary-color;
    font-size: 20px;
    cursor: pointer;
  }
  .ant-avatar-lg {
    border: 1px solid @white;
    margin-right: 5px;
  }
  span {
    svg {
      color: @white;
      font-size: @font-14 - 2 ;
    }
  }
  .trigger-section {
    padding-right: 10px;
    z-index: 1;
    cursor: pointer;
  }
  .anticon {
    position: absolute;
    right: 0;
  }
}

.header__logo {
  padding: 0 1rem;
  padding: 0 1rem;
  padding-left: 1.5rem;
  width: 250px;
  font-family: "Russo One", sans-serif;
  font-size: 40px;
  font-weight: bold;
  text-transform: capitalize;
  transition: all 0.3s ease;
  color: @logo-color;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 auto;
  &.shrinked {
    width: 150px;
  }
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  align-items: center;
  @media (max-width: 959px) {
   
        max-width: 150px;
        
    
}
 
}

.btn__menu__toggle {
  color: @white;
  &:hover, 
  &:focus,
  &:active {
    color: @white;
  }
}

.profile__dropdown  {
  .ant-dropdown-menu-item{
    border-bottom: 1px solid #F1F1F3 !important;
    &:last-child{
      border-bottom: 0 !important;
    }
  }
  .ant-dropdown-menu-title-content > a {
    color: @p-text-color !important;
    font-size: @font-14 - 1;
  }
  .link__iocns {
    display: inline-flex !important;
  }
}
