.profile-page-wrapper {
  display: inline-block;
  .profile-detail {
    display: flex;
    &-avatar {
      width: 195px;
      height: 250px;
      border-radius: 4px;
      box-shadow: 0 3px 8px #3E649229;
      margin-right: 24px;
      .ant-image {
        img {
          height: 250px;
          object-fit: cover;
          &.loader_image {
            object-fit: contain;

          }
        }

      }
    }
    .info-header {
      border-bottom: 1px solid #F1F1F3;
      padding-bottom: 10px;
      margin-bottom: 10px;
      h5 {
        font-weight: @bold;
        font-size: @font-md-sm;
      }
      span {
        font-size: @font-regular;
      }
    }
  }
  
}
.profile-table {
  width: 100%;
  // margin-bottom: 15px;
  td {
    position: relative;
  }
  .colon-right {
    float: right;
    padding-left: 5px;
    position: absolute;
    right: 0;
  }
}


.changepassword-modal {
  max-width: 400px;
  .ant-modal-content {
    height: 450px;
    .ant-modal-body {
      padding: 16px 24px 24px;
      .ant-form-item {
        margin-bottom: 25px;
        &.ant-form-item-has-error {
          margin-bottom: 10px;
        }
        .actionBtn {
          margin-top:8px;
          .cancel-btn {
            width:100%;
            max-width: 120px;
             margin-right: 16px;
          }
          .change-btn {
            margin-right: 0px!important;
            max-width: 216px;
            width: 100%;
          }
        }
      }
      .ant-form-item-explain-error {
        position: absolute;
        width: 100%;
        margin-top: 3px;
        line-height: 16px;
      }
    }
  }
  .ant-input {
    font-size: 18px;
    &::placeholder {
      font-size: 12px;
    }
  }
  
}

.password-change-modal {
  width: 330px !important;
  .ant-modal-content {
    padding: 7px 16px 15px 24px;
    .ant-modal-title {
      h5 {
        font-size: 15px;
        font-weight: 600;
        margin-top: 15px;
        margin-bottom: 0;
        text-align: left;
      }
    }
    .ant-modal-body {
      padding: 10px 0px;
      p {
        text-align: left !important;
        line-height: 19px;
      }
    }
    .ant-modal-footer {
      padding:10px 0px 5px;
    }
  }
  .pwdChangeBtn {
    display: flex;
    justify-content: center;
    .btn {
      width:132px;
    }
  }
}

.button_grey {
  background-color: #919191 !important
}