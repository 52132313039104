.ant-drawer-body{
    padding: 0;
    .drawer__header{
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .drawer__trigger{
            padding: 0;
        }
    }
    .drawer__trigger{
        border: none;
    }
}