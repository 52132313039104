.error__page__wrapper{
    .page__inside{
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
        max-width: 1200px;
        padding: 0 20px;
        margin: auto;
        img{
            max-width: 500px;
            // @include md{
            //     max-width: 100%;
            //     height: auto;
            // }
        }
        .page__content {
            width: 533px;
            margin-right: 60px;
        }
        // @include xl{
        //     flex-direction: column-reverse;
        //     text-align: center;
        // }
    }
    h1{
        font-size: 50px;
        font-weight: @bold;
        // @include xl{
        //     font-size: 36px;
        // }
        // @include md{
        //     font-size: 24px;
        //     margin-bottom: 10px;
        // }
    }
    p{
        font-size: 18px;
        // color: @sidebar-toggle-btn-color;
        line-height: 32px;
        line-height: 24px;
        // @include md{
        //     font-size: 14px;
        // }
    }
    .link__goback{
        margin-top: 20px;
    }
}

@media screen and (max-width:991px)   {
    .error__page__wrapper {
        h1 {
            font-size: 28px;
        }
        p {
            font-size: 14px;
            line-height: 19px;
        }
        .page__inside {
            display: flex;
            flex-direction: column-reverse;
            .page__content {
                text-align: center;
                width: 100%;
                margin:10px auto;
            }
            

        }
    }

}

@media screen and (max-width:500px)   {
    .error__page__wrapper {
        h1 {
            font-size: 28px;
        }
        p {
            font-size: 14px;
            line-height: 19px;
        }
        .page__inside {
            img {
                max-width:300px !important;
            }

        }
    }

}