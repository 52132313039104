.ant-btn {
  background-color: transparent;
  color: @p-text-color;
  margin-right: 10px;
  box-shadow: none;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: @p-text-color;
  }
  &::after {
    display: none !important;
  }
  &.success-outlined-btn {
    // background-color: @lime-base;
    border-color: @lime-base;
    color: @lime-base;
  }
  &.outlined-btn {
    border-color: @primary-color;
    color: @primary-color;
  }
  &.dander-outlined-btn {
    border-color: @red-base;
    color: @red-base;
  }
  &.success-btn {
    background-color: @lime-base;
    color: @white;
  }
  &.login__btn {
    background-color: #2a59d1;
    border-color: #2a59d1;
    border-radius: 0.625rem;
    color: @white !important;
    height: 40px;
    &:hover {
      background-color: #699dff;
      border-color: #699dff;
    }
  }
  &.learn__more__btn {
    background-color: #2a59d1;
    border-color: #2a59d1;
    border-radius: 0.625rem;
    color: @white !important;
    height: 40px;
    padding: 8px 45px;
    margin-top: 20px;
    &:hover {
      background-color: #699dff;
      border-color: #699dff;
    }
  }
  // &.ant-btn-default{
  //     background-color: @sidebar-toggle-btn-color !important;
  //     border-color: @sidebar-toggle-btn-color !important;
  //     color: @white;
  //     &:hover{
  //         background-color: lighten(@sidebar-toggle-btn-color, 5%) !important;
  //     }
  // }
}

.btn__menu__toggle {
  // color: #455560;
  border: none;
  background-color: transparent !important;
  border-radius: 0;
  font-size: 22px;
  padding: 1rem;
  margin-right: 1rem;
  height: 100%;
  // @include flexAlignCenter;
  animation-duration: 0s;
  outline: none;
  box-shadow: none !important;
  &:hover {
    color: @primary-color;
  }
  @media @lg {
    display: none;
  }
}
.drawer__trigger {
  display: none;
  @media @lg {
    display: block;
  }
}
.connect__options {
  button {
    border-radius: 0.625rem;
    border: 1px solid #e6ebf1;
    color: #455560 !important;
    height: 40px;
    img {
      margin-right: 0.5rem;
    }
    &:hover {
      border-color: #e6ebf1;
      background-color: #e6ebf1;
    }
    &:first-child {
      margin-right: 0.5rem;
    }
  }
}
.btn__search__sm {
  background-color: transparent !important;
  border: none;
}

.btn {
  padding: 7px 10px;
  border-radius: 4px;
  color: @white;
  font-weight: @semibold;
  border: 0;
  min-width: 100px;
  height: 42px;
  line-height: 27px;
  font-size: @font-regular;
  &.btn-primary {
    background-color: @primary;
    &:hover {
      background-color: @primary-light;
    }
  }
  &.btn-success {
    background-color: @success;
    &:hover {
      background-color: @success-light;
    }
  }
  &.btn-danger {
    background-color: @danger;
    &:hover {
      background-color: @danger-hover;
    }
  }
  &.btn-default {
    background-color: #9FA9BC;
    &:hover {
      background-color: #868e9d;
    }
  }
  &:hover,
  &:active,
  &:focus  {
    color: @white;
  }
}

.btns-group {
  display: flex;
  .btn, button {
    margin-right: 0;
    &:first-child {
      margin-right: 10px;
    }
  }
}

.btn-link {
  padding: 0;
  height: auto;
  font-weight: 400;
  font-size: 13px;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: inherit;
  text-align: left;
  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
    color: inherit;
  }

}
