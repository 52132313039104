.page__wrapper {
  margin: 70px 0 0 250px;
  transition: all 0.3s ease;
  padding: 30px;
  position: relative;
  padding-top: 80px;
  &.expanded {
    margin-left: 80px;
    @media @lg {
      margin-left: 0;
    }
  }
  @media @lg {
    margin-left: 0;
  }
  @media (max-width: 575px) {
    padding: 20px;
    padding-top: 60px;
  }
  .card-title {
    font-weight: 400;
  }
  .ant-breadcrumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: @white;
    box-shadow: 0 3px 8px #3E649229;
    padding: 10px 10px 10px 30px;
    .ant-breadcrumb-link,
    .ant-breadcrumb-link a,
    .ant-breadcrumb-separator {
        color: @danger-text;
        font-size: @font-14 + 1;
        font-weight: @semibold;
    }
  }
}
