.authentication__wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: url('../images/authentication-bg.jpg')no-repeat center/cover;
  text-align: center;
  .authentication__content {
    background-color: @white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    max-width: 1240px;
    width: 100%;
    margin: 0 20px;
    > div {
      .input__groups {
        margin-bottom: 1.5rem;
      }
    }
    .alert__wrapper {
      padding: 15px 0;
      text-align: left;
      .ant-alert-message {
        color: rgb(26, 51, 83);
      }
      .ant-alert-close-icon {
        position: absolute;
        opacity: 0;
        border-radius: 50%;
        height: 15px;
        width: 16px;
        left: 6px;
        overflow: hidden;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .connect__wrapper {
      margin-bottom: 16px;
      span {
        position: relative;
        display: inline-block;
        color: rgba(114, 132, 154, 0.7);
        &::before {
          position: absolute;
          top: 50%;
          left: 110%;
          width: 80px;
          height: 1px;
          background-color: #e6ebf1;
          content: "";
        }
        &::after {
          position: absolute;
          top: 50%;
          right: 110%;
          width: 80px;
          height: 1px;
          background-color: #e6ebf1;
          content: "";
        }
      }
    }
    .ant-input-affix-wrapper {
      border-radius: 0.625rem;
      padding: 8.5px 11px;
      
      input {
        height: auto;
        border-radius: 0;
        background-color: transparent !important;
      }
      &.ant-input-affix-wrapper-focused {
          box-shadow: none;
      }
    }
    .ant-col {
      &.ant-form-item-label {
        position: relative;
        font-weight: 600;
      }
    }
    .ant-input-prefix {
      .anticon {
        color: rgba(62, 121, 247, 1);
      }
    }
    .ant-form-item-explain-error {
      text-align: left;
      margin-bottom: 10px;
    }
    .form-wrap {
      width: 100%;
      padding: 0 150px;
      h3 {
        font-size: @font-md-xs;
        font-weight: @bold;
      }
    }
  }
  .ant-image {
    width: 100%;
    height: 100%;
    .login-banner {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }

  @media (max-width: 959px) {
    .authentication__wrapper{
      display:'grid';
      place-items:'center';
    }
}
  
}

.form__wrapper {
  a {
    &:hover,
    &:active,
    &:focus {
      color: @link-text-color;
      text-decoration: underline;
    }
  }
  .auth_form-item {
    position: relative;
    box-shadow: 0 3px 8px #3E649229;
    margin-bottom: 8px;
    padding: 8px;
    padding-left: 20px;
    &::before {
      content: '';
      display: block;
      width: 5px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: @theme-gradient;
    }
    input,.ant-input-affix-wrapper {
      border: 0;
      border-radius: 0;
      background-color: transparent !important;
      padding: 0;
      height: auto;
      font-size: @font-md;
      &:focus {
        box-shadow: none;
      }
    }
    .ant-form-item-control-input-content {
      height: auto;
    }
    .ant-input {
      color: #9FA9BC !important;
      font-size: 18px;
      &:focus {
        border: none !important;
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
    .ant-form-item-label {
      padding-bottom: 4px;
    }
    label {
      text-transform: uppercase;
      font-size: @font-14;
      font-weight: @semibold;
      &::before {
        display: none !important;
      }
    }
  }
}

.auth__btn {
  border: 0;
  font-size: @font-md-xs;
  font-weight: @xbold;
  text-transform: uppercase;
}

.emailchange-text {
  background-color: #fb6c6c;
    color: #fff;
    border-radius: 4px;
    padding: 15px 15px;
    margin-top: 10px;
    text-align: center;
}