.permission-wrap {
    &.disabled {
        cursor: not-allowed;
        .ant-checkbox-group {
            span:nth-child(2) {
                color:#3a3a3c;
                opacity: 0.5;
            }
        }
        
        .ant-checkbox-checked {
            .ant-checkbox-inner {
                background-color: #e1e1e1;
                border-color: #3a3a3c;
                &::after {
                    border: 1px solid #3a3a3c;
                    border-top: 0 !important;
                        border-left: 0 !important;
                }
            }
        }
        .ant-checkbox-inner {
            background-color: #e1e1e1;
            border: 1px solid #3a3a3c;
        }
        .permissions {
            pointer-events: none;
            &-head {
                span:nth-child(2) {
                    color:#3a3a3c;
                    opacity: 0.5;
                }
            }
        }
    
    }
    
}
.permissions {
    border: 1px solid #E1E1E1;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: @radius;
    &-head {
        h5 {
            font-size: @font-regular;
            font-weight: @bold;
        }
        .ant-checkbox-wrapper {
            margin-right: 30px;
            .ant-checkbox  {
                left: 85px;
            }
        }
        .ant-checkbox-inner {
            &::after {
                display: none;
            }
        }
        .ant-checkbox-checked {
            .ant-checkbox-inner {
                &::after {
                    display: block;
                }
            }
        }
    }
    .roles-checks {
        width: 100%;
        label {
            width: 24%;
            margin-top: 18px;
        }
    }
}

.view_permission {
    font-size: 16px !important;
    line-height: 15px;
}


.viewbtn_block {
    cursor: not-allowed;
    width: 140px;
    background: #aeaeae;
    border-radius: 4px;
}
.viewBtn {
    &.pointereventsNone {
        pointer-events: none;
        background: #aeaeae;
    }
}