.error__boundary__wrapper{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1{
        font-size: 50px;
        font-weight: @bold;
        margin-bottom: 15px;
        margin-top: 30px;
    }
}