.table-responsive {
  max-width: 100%;
  overflow-x: auto;
}
table {
  margin-bottom: 0;
  td,th {
    white-space: nowrap;
  }
}

.action__btn__wrapper {
  .ant-btn {
    margin-right: 10px;
    text-transform: capitalize;
    &:last-child {
      margin-right: 0;
    }
  }
}

.table th,
.table td {
  border-right: 1px solid;
  white-space: nowrap;
}
.ant-table-thead > tr > th {
  font-weight: 600;
  background-color: #F5F6FA !important;
}
.ant-table-thead > tr > th:first-child {
  border-left: 0 !important;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #F1F1F3 !important;
  // border-left: 1px solid @border-color !important;
  //   color: $secondary-color;
  // text-align: right;
}
.ant-table-tbody > tr > td:first-child {
  border-left: 0 !important;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: transparent;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 10px 15px;
}

.table-responsives {
  .ant-table-content {
    overflow-x: auto;
  }
}
.expandad__table {
  > .ant-table-cell {
    padding: 0;
    .ant-table {
      margin: 0 !important;
      border: none !important;
      background-color: #f4f4f4;
    }
  }
}

.table-form {
  .ant-input,
  .ant-input-password,
  .ant-input-number-input {
    min-height: 32px;
    padding: 5px 7px;
  }
}

.amount-cell {
  input {
    text-align: end;
  }
}

.total-amount-footer {
  text-align: end;
}

.table-footer {
  .ant-form-item {
    margin-bottom: 0px;
  }
}
.table-responsives {
  &::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
  }
  &::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 5px solid #fff;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
    border: 4px solid #f4f4f4;
  }
}

.ant-table{
  .ant-table-container{
    &::before {
      display: none !important;
    }
  }
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  display: none !important;
}
